import { type Dispatch, type ReactNode, type SetStateAction, createContext, useContext, useState } from 'react'
import { type Stores } from 'swh/shared/http'

type State = {
  store: Stores.Item
  cnpj: string
}

// TODO: There is a type conflict between `[State, Dispatch<SetStateAction<State>>]` and `null`. Remove `@ts-ignore` comments and run `pnpm check-types`.
// @ts-ignore
const SwhConfigContext = createContext<[State, Dispatch<SetStateAction<State>>]>(null)

export const SwhConfigContextProvider = ({ children }: { children: ReactNode }) => {
  // @ts-ignore
  const value = useState<State>(null)
  // @ts-ignore
  return <SwhConfigContext.Provider value={value}>{children}</SwhConfigContext.Provider>
}

export const useSwhConfigContext = () => useContext(SwhConfigContext)

export const useSwhConfig = () => useContext(SwhConfigContext)[0]
