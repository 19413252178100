export const CREDIT_ROUTES = [
  '/credito',
  '/emprestimo', // usada como deeplink
  '/emprestimo/proposta/simulacao', // usada como deeplink
  '/credito/emprestimo/proposta/simulacao',
  '/credito/emprestimo/proposta/atualizar-dados',
  '/credito/emprestimo/proposta/nacionalidade',
  '/credito/emprestimo/proposta/estado-civil',
  '/credito/emprestimo/proposta/endereco',
  '/credito/emprestimo/proposta/confirmar-dados',
  '/credito/emprestimo/proposta/revisao',
  '/credito/emprestimo/proposta/sucesso',
  '/credito/emprestimo/proposta/pin',
  '/credito/emprestimo/proposta/confirmar-pedido',
  '/credito/emprestimo/${loan}',
  '/credito/emprestimo/${loan}',
  '/credito/emprestimo/${loan}/antecipar-pagamento/${step}',
  '/credito/emprestimo/${loan}/antecipar-pagamento/inicio',
  '/credito/emprestimo/${loan}/parcela/${installment}',
  '/credito/emprestimo/${loan}/renegociacao',
  '/credito/emprestimo/${loan}/renegociacao/${step}',
  '/credito/emprestimo/${loan}/renegociacao/simulacao',
  '/credito/emprestimo/${loan}/renegociacao-condicoes',
  '/credito/emprestimo/${loan}/renegociacao-parcelas',
  '/credito/emprestimo/${loan}/credito/emprestimo-original/parcela/${installment}',
  '/credito/giro-facil/${product}',
  '/credito/giro-facil/${product}/antecipar-pagamento/${step}',
  '/credito/giro-facil/${product}/saque/valor',
  '/credito/giro-facil/${product}/saque/sucesso',
  '/credito/giro-facil/${product}/contratacao/inicio',
  '/credito/giro-facil/${product}/contratacao/${step}',
  '/credito/giro-facil/${product}/demonstrativo',
  '/credito/giro-facil/${product}/antecipar-pagamento/inicio',
  '/roleta',
  '/credito/giro-facil/contratacao'
] as const

export const renegotiationBasePath = '/credito/emprestimo/${loan}/renegociacao'
export const loanConcessionBasePath = '/credito/emprestimo/proposta/simulacao'
export const revolvingCreditConcessionBasePath = '/credito/giro-facil/contratacao'
export const creditHomeBasePath = '/credito'
