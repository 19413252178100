import { type FC, type PropsWithChildren, type ReactNode, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useToggle } from 'react-use'
// TODO: [Web Platform Refactor] Fix this circular dependencie
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useExperiment } from 'platform/lib/experiment'

import { Header } from '~/domains/home-v2/shell/header/header'
import { Sidebar } from '~/domains/home-v2/shell/sidebar'
import { Heimdall } from '~/domains/platform/core/heimdall'
import { type Entity, type Subject } from '~/domains/platform/infra/deus-ex-machina/ports'
import { logger } from '~/domains/platform/infra/monitoring/logger'
import BlackBird from '~/domains/platform/lib/blackbird'
import { HiddenSensitiveProvider } from '~/domains/platform/lib/hidden-sensitive/hooks/use-hidden-sensitive'
import { Choose } from '~/domains/platform/lib/utilities-components'
import { MainMenu } from '~/domains/platform/shell/main-menu/main-menu'
import { BankingMenu } from '~/ui/business-components/banking/banking-menu'
import { CustomSidebar } from '~/ui/components/layouts/custom-sidebar'
import { SidebarLayout } from '~/ui/components/layouts/sidebar-layout/sidebar-layout'
import { type TopBarLayoutProps, TopBarLayout } from '~/ui/components/layouts/top-bar-layout'
import { ProfileSwitch } from '~/ui/components/navigation/profile-switch'
import { GenericErrorMessage } from '~/ui/components/utils/generic-error-message/generic-error-message'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

export interface NavigationProps {
  entity: Entity<'banking' | 'acquirer'>
  subject: Subject<'stone_account_resources'>
  children?: ReactNode
  fullscreen?: boolean
}

export const Navigation: FC<React.PropsWithChildren<NavigationProps>> = ({
  subject,
  entity,
  children,
  fullscreen
}: NavigationProps) => {
  const { isViewMedium, isWidthMedium } = useViewSize()
  const [open, toggleOpen] = useToggle(false)
  const [isSwappingAccount, toggleSwappingAccount] = useToggle(false)

  useEffect(() => {
    globalThis.document.body.style.overflow = 'initial'

    if (open === true && isViewMedium) {
      globalThis.document.body.style.overflow = 'hidden'
    }
  }, [isViewMedium, open])

  useEffect(() => {
    if (isWidthMedium) {
      globalThis.document.body.style.overflow = 'initial'
      toggleOpen(true)
    }
  }, [toggleOpen, isWidthMedium])

  const { isFeatureFlagEnabled, isLoading } = useExperiment(subject.id, 'flag-new-home-web')

  const handleGoToSwitchAccount = () => {
    const hasEntityAccountList = Heimdall.pass(['entity_account_list'])

    if (hasEntityAccountList) {
      BlackBird.select()
    } else {
      toggleSwappingAccount()
    }
  }

  return (
    <HiddenSensitiveProvider>
      <HeaderV2
        toggleOpen={toggleOpen}
        open={open}
        subject={subject}
        entity={entity}
        isFeatureFlagEnabled={isFeatureFlagEnabled}
        components={{
          topBarContent: <BankingMenu subject={subject} entity={entity} />,
          asideContent: <CustomSidebar open={open} toggleOpen={toggleOpen} />
        }}
      >
        <SidebarLayout
          open={open}
          fullscreen={fullscreen}
          aside={
            <Choose>
              <Choose.When condition={isSwappingAccount}>
                <ProfileSwitch currentEntity={entity} callback={handleGoToSwitchAccount} subject={subject} />
              </Choose.When>
              <Choose.Otherwise>
                {!isLoading && (
                  <Choose>
                    <Choose.When condition={isFeatureFlagEnabled}>
                      <Sidebar entity={entity} subject={subject} />
                    </Choose.When>
                    <Choose.Otherwise>
                      <MainMenu entity={entity} callback={handleGoToSwitchAccount} />
                    </Choose.Otherwise>
                  </Choose>
                )}
              </Choose.Otherwise>
            </Choose>
          }
        >
          <ErrorBoundary
            onError={error => {
              logger(error)
            }}
            FallbackComponent={() => <GenericErrorMessage />}
          >
            {children}
          </ErrorBoundary>
        </SidebarLayout>
      </HeaderV2>
    </HiddenSensitiveProvider>
  )
}

interface HeaderV2Props {
  components?: PropsWithChildren<TopBarLayoutProps>
  children?: ReactNode
  entity: Entity<'acquirer' | 'banking'>
  subject: Subject<'stone_account_resources'>
  open: boolean
  toggleOpen: () => void
  isFeatureFlagEnabled: boolean
}

function HeaderV2(props: HeaderV2Props) {
  const { components, children, entity, subject, open, toggleOpen, isFeatureFlagEnabled } = props

  return (
    <Choose>
      <Choose.When condition={isFeatureFlagEnabled}>
        <Header toggleOpen={toggleOpen} entity={entity} subject={subject} open={open}>
          {children}
        </Header>
      </Choose.When>
      <Choose.Otherwise>
        <TopBarLayout topBarContent={components?.topBarContent} asideContent={components?.asideContent}>
          {children}
        </TopBarLayout>
      </Choose.Otherwise>
    </Choose>
  )
}
