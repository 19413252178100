import { type QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'
import { handleErrorResponse } from '../utils'

export namespace SaleDetail {
  export type Response = {
    amount: number
    updatedAmount: number
    status: string
    paymentMethod: string
    createdAt: string
    merchant: Merchant
    boletoTransaction: BoletoTransaction | null
    cardTransaction: CardTransaction | null
    pixTransaction: PixTransaction | null
    netAmount: number
  }

  export type Merchant = {
    organization: string
    document: string
    userId: string
    affiliationKey: string
  }

  export type BoletoTransaction = {
    type: string
    transactionId: string
    ourNumber: string
    fineAmount: number
    interestAmount: number
    feeAmount: number
    expireAt: string
    writableLine: string
    bank: Bank
    fine: Fine
  }

  export type Bank = {
    name: string
  }

  export type Fine = {
    value: string
  }

  export type CardTransaction = {
    type: string
    transactionId: string
    capturedAmount: number
    canceledAmount: number
    chargebackAmount: number
    chargebackRefundAmount: number
    authorizationCode: string
    installments: number
    card: Card
    details: Detail[]
    feeAmount: number
    mdrAmount: number
    mdrRefundAmount: number
    ravAmount: number
    ravRefundAmount: number
    terminal: Terminal
  }

  export type Card = {
    brand: string
    firstSixDigits: string
    lastFourDigits: string
  }

  export type Detail = {
    operation: string
    providerDatetime: string
    operationReason: string
  }

  export type Terminal = {
    type: string
    serialNumber: string
  }

  export type PixTransaction = {
    transactionId: string
    e2eId: string
    feeAmount: number
    payer: Payer
    terminal: Terminal
  }

  export type Payer = {
    name: string
    document: string
    institutionName: string
  }

  const getSale = async (ctx: QueryFunctionContext): Promise<Response> => {
    const [, cnpj, saleId] = ctx.queryKey
    try {
      const response = await httpClient.get<Response>(Endpoints.sales.getSaleById(cnpj as string, saleId as string))
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }
  export function useFetchSalesById(cnpj: string, saleId: string) {
    return useQuery<Response, unknown>({
      queryKey: ['sales-by-id', cnpj, saleId],
      queryFn: getSale,
      refetchOnWindowFocus: false,
      enabled: !!cnpj && !!saleId
    })
  }
}
