/* eslint-disable testing-library/no-await-sync-query */
import { useEffect } from 'react'
import { type NextPage } from 'next'
import { Stores } from 'swh/shared/http'

import { useDeusExMachina } from '~/domains/platform/infra/deus-ex-machina/hocs/with-deus-ex-machina'
import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'
import { isValidCnpj } from '~/lib/helpers'

import { useSwhConfigContext } from './swh-config-context'

const WithSwhConfig = ({ Page, ...props }: any) => {
  const { deusExMachina } = useDeusExMachina()
  const [config, setConfig] = useSwhConfigContext()
  const entity = deusExMachina?.entity as Entity<'acquirer'>

  useEffect(() => {
    const fetchStoreConfig = async () => {
      if (entity?.document && isValidCnpj(entity.document)) {
        const store = await Stores.getByDocument(entity.document)
        setConfig({ store: store, cnpj: entity.document })
      }
    }

    fetchStoreConfig()
  }, [entity, setConfig])

  if (!entity || !config) {
    console.error('Missing SWH Config or Acquirer Entity')
    return null
  }

  return <Page {...props} />
}

export function withSwhStoreConfig<P extends NextPage<any, any>>(Page: P) {
  return (props: any) => <WithSwhConfig {...props} Page={Page} />
}
