import { ActionButton, Divider, Icon, Link, ShortcutButton, Text } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'
import styled from 'styled-components'

import { useGetHome } from '~/domains/home-v2/shared/hooks/get-bff'
import { type Actions, type Analytics } from '~/domains/home-v2/types'
import { Flex } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import { type Entity, type Subject } from '~/domains/platform/infra/deus-ex-machina/types'
import BlackBird, { type Routes } from '~/domains/platform/lib/blackbird'
import { useHiddenSensitive } from '~/domains/platform/lib/hidden-sensitive/hooks/use-hidden-sensitive'
import { Choose, For, If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import analitica from '../analitica'

const CustomFlex = styled(Flex)`
  padding: ${jadeTheme.global.space[150]};
  gap: ${jadeTheme.global.space[50]};
  border-radius: ${jadeTheme.global.space[100]};
  cursor: pointer;
  &:hover {
    background: ${jadeTheme.theme.color.background['surface-hover']};
    transition: 0.2s ease-out;
  }
`

function BalanceCardSkeleton() {
  return (
    <>
      <CustomFlex flexDirection="column">
        <Skeleton width="40%" height={jadeTheme.global.space[250]} />
        <Skeleton width="80%" height={jadeTheme.global.space[250]} />
        <Skeleton width="80%" height={jadeTheme.global.space[250]} />
        <Skeleton width="80%" height={jadeTheme.global.space[250]} />
      </CustomFlex>
      <CustomFlex flexDirection="column">
        <Skeleton width="40%" height={jadeTheme.global.space[250]} />
        <Skeleton width="80%" height={jadeTheme.global.space[250]} />
      </CustomFlex>
    </>
  )
}

export function BalanceCard({
  entity,
  subject
}: {
  entity: Entity<'acquirer' | 'banking'>
  subject: Subject<'stone_account_resources'>
}) {
  const { balance, isLoading } = useGetHome({
    document: subject?.document,
    accountId: entity?.paymentAccount?.id,
    globalId: entity.id,
    affiliationKey: entity?.stoneCodes[0]?.affiliationKey
  })

  const handleClick = ({ uri, analytics }: { uri: Routes; analytics: Array<Analytics> }) => {
    if (analytics)
      analytics
        .filter(event => event.type === 'CLICKED')
        .forEach(event => {
          analitica.events.balanceCard.actionClick({
            eventName: event?.eventName,
            type: event?.type,
            properties: event?.properties
          })
        })

    BlackBird.travelTo(uri)
  }

  const [hiddenSensitive, triggerToggle] = useHiddenSensitive()

  return (
    <If condition={balance?.show}>
      <Flex flexDirection="column" p={jadeTheme.global.space[100]} data-testid={balance?.id}>
        <Choose>
          <Choose.When condition={isLoading}>
            <BalanceCardSkeleton />
          </Choose.When>
          <Choose.Otherwise>
            <Flex position="relative">
              <Flex
                position="absolute"
                justifyContent="end"
                right={0}
                top={0}
                mr={jadeTheme.global.space[50]}
                mt={jadeTheme.global.space[50]}
                zIndex={1}
              >
                <ActionButton
                  aria-label={hiddenSensitive ? 'esconde valores' : 'mostra valores'}
                  onClick={() => {
                    triggerToggle()
                    analitica.events.balanceCard.hideBalance(hiddenSensitive ? 'hide' : 'show')
                  }}
                  active={hiddenSensitive}
                  icon={!hiddenSensitive ? 'eye-open' : 'eye-close'}
                />
              </Flex>
            </Flex>
            <CustomFlex
              onClick={() => {
                if (balance?.balance?.navigation?.uri) {
                  handleClick({
                    uri: balance.balance.navigation.uri,
                    analytics: balance?.balance?.analytics as Array<Analytics>
                  })
                }
              }}
              flexDirection="column"
              role="statement-link"
            >
              <Text variant="text-small" weight="regular" color="medium">
                {balance?.balance?.label}
              </Text>
              <Text variant="heading-medium">
                {hiddenSensitive ? '••••' : stringFormat.currency(balance?.balance?.amount)}
              </Text>
              <If condition={balance?.scheduled?.show}>
                <Flex gap={jadeTheme.global.space[50]}>
                  <Icon size="small" use="circle-clock" color="medium" />
                  <Text variant="text-small" weight="regular" color="medium">
                    {hiddenSensitive ? '••••' : stringFormat.currency(balance?.scheduled?.amount)}{' '}
                    {balance?.scheduled?.label}
                  </Text>
                </Flex>
              </If>
              <If condition={balance?.blocked?.show}>
                <Flex gap={jadeTheme.global.space[50]}>
                  <Icon size="small" use="lock-close" color="medium" />
                  <Text variant="text-small" weight="regular" color="medium">
                    {hiddenSensitive ? '••••' : stringFormat.currency(balance?.blocked?.amount).replace('-', '')}{' '}
                    {balance?.blocked?.label}
                  </Text>
                </Flex>
              </If>
            </CustomFlex>
            <If condition={balance?.investments?.show}>
              <CustomFlex
                onClick={() => {
                  if (balance?.investments?.navigation?.uri) {
                    handleClick({
                      uri: balance.investments.navigation.uri,
                      analytics: balance?.investments?.analytics as Array<Analytics>
                    })
                  }
                }}
                flexDirection="column"
                role="investment-link"
              >
                <If condition={balance?.investments?.amount > 0}>
                  <Text variant="heading-xsmall">
                    {hiddenSensitive ? '••••' : stringFormat.currency(balance?.investments?.amount)}
                  </Text>
                </If>
                <Text variant="text-medium" weight="medium">
                  {balance?.investments?.title ?? undefined}
                </Text>
                <Text variant="text-small" weight="regular" color="medium">
                  {balance?.investments?.label ?? undefined}
                </Text>
                <If condition={Boolean(balance?.investments?.navigation?.text)}>
                  <Flex gap={jadeTheme.global.space[50]} alignItems="center">
                    <Text variant="text-medium" weight="medium" color="brand">
                      {balance?.investments?.navigation?.text ?? undefined}
                    </Text>
                    <Icon size="small" use="chevron-right" color="brand" />
                  </Flex>
                </If>
              </CustomFlex>
            </If>
            <If condition={balance?.creditRevolving?.show}>
              <CustomFlex
                role="credit-revolving"
                flexDirection="column"
                gap={jadeTheme.global.space[50]}
                onClick={() => {
                  if (balance?.creditRevolving?.navigation?.uri) {
                    handleClick({
                      uri: balance.creditRevolving.navigation.uri,
                      analytics: balance?.creditRevolving?.analytics as Array<Analytics>
                    })
                  }
                }}
              >
                <Text variant="text-medium" weight="medium">
                  {balance?.creditRevolving?.title}
                </Text>
                <Text variant="text-small" weight="regular" color="medium">
                  {balance?.creditRevolving?.description}
                </Text>
                <Link>{balance?.creditRevolving?.navigation?.text as string}</Link>
              </CustomFlex>
            </If>
            <Flex justifyContent="space-between" p={jadeTheme.global.space[100]}>
              <For
                of={balance?.actions as Array<Actions>}
                render={item => {
                  const { label, locked, icon, uri, analytics } = item

                  return (
                    <Flex key={label} position="relative">
                      <If condition={locked}>
                        <Flex
                          p={jadeTheme.global.space[50]}
                          borderRadius="1rem"
                          position="absolute"
                          right={0}
                          top={0}
                          bg={{ background: jadeTheme.theme.color.background.info }}
                        >
                          <Icon color="on-color" size="small" use="lock-close" />
                        </Flex>
                      </If>
                      <ShortcutButton
                        icon={icon}
                        label={label}
                        disabled={locked}
                        onClick={() => {
                          handleClick({
                            uri: uri,
                            analytics: analytics
                          })
                        }}
                      />
                    </Flex>
                  )
                }}
              />
            </Flex>
          </Choose.Otherwise>
        </Choose>
      </Flex>
      <Flex p={jadeTheme.global.space[100]}>
        <Divider />
      </Flex>
    </If>
  )
}
